import React, { CSSProperties, FC } from 'react'
import './alarmCardDetail.sass'
import { toStringWithPrecision } from '../../../../../utils/toStringWithPrecision'
import { formatDateTimeString } from '../../../../../utils/formatDateString'
import { Failure } from '../../../../../Contexts/types'
import { AlarmCardDetail } from './alarmCardDetail'
import { getFailureStateBorderColor } from '../../../../../utils/getFailureStateColor'
import { formatFailureState } from '../../../../../utils/formatFailureType'
import { Box } from '@mui/material'

const propertyLabels = {
  messageType: 'Message',
  alarmType: 'Type',
  countOfWarnings: 'Count',
  firstOccurrence: 'First Occurrence',
  lastOccurrence: 'Last Occurrence',
  value: 'Value measured',
  violatedThresholdValue: 'Threshold violated',
  autoFeedback: 'Autofeedback',
  status: 'Status',
  highestFailureState: 'Highest state'
}

interface AlarmCardDetailsProps {
  failure: Failure
}

export const AlarmCardDetails: FC<AlarmCardDetailsProps> = ({ failure }) => {
  let highestStateValue = ''
  let highestStateValueStyles: CSSProperties | undefined

  if (failure.highestFailureState) {
    highestStateValue = formatFailureState(failure.highestFailureState)
    highestStateValueStyles = {
      backgroundColor: getFailureStateBorderColor(failure.highestFailureState),
      padding: '2px 8px 4px 6px',
      borderRadius: '4px',
      fontSize: '13px'
    }
  }

  return (
    <Box className='alarm-card-details'>
      <AlarmCardDetail
        label={propertyLabels.highestFailureState}
        value={highestStateValue}
        valueStyles={highestStateValueStyles}
      />
      <AlarmCardDetail
        label={propertyLabels.value}
        value={toStringWithPrecision(failure.value, 4)}
      />
      <AlarmCardDetail
        label={propertyLabels.violatedThresholdValue}
        value={
          failure.violatedThresholdValue
            ? toStringWithPrecision(failure.violatedThresholdValue, 4)
            : ''
        }
      />
      <AlarmCardDetail
        label={propertyLabels.firstOccurrence}
        value={formatDateTimeString(new Date(failure.firstOccurrence))}
      />
      <AlarmCardDetail
        label={propertyLabels.lastOccurrence}
        value={formatDateTimeString(new Date(failure.lastOccurrence))}
      />
    </Box>
  )
}
