import { MRT_ColumnDef } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import React, { useMemo } from 'react'
import { Tooltip, Typography } from '@mui/material'
import { Row } from '@tanstack/table-core/src/types'
import { mediumFontSize } from '../cellFontSizes'

export const failureTypeColumnId = 'failureType'

export const useFailureTypeColumnHeader = (
  data: FailuresListEntry[]
): MRT_ColumnDef<FailuresListEntry> => {
  const selectOptions = useMemo<string[]>(() => {
    const currentStates = data.map((entry) => entry.failureType)
    const distinctCurrentStates = currentStates.filter(
      (item, index) => currentStates.indexOf(item) === index
    )

    return distinctCurrentStates.sort((a, b) => (a > b ? 1 : -1))
  }, [data])

  return {
    id: failureTypeColumnId,
    header: 'Failure Type',
    accessorKey: 'failureType',
    enableColumnDragging: false,
    enableColumnActions: false,
    enableColumnFilterModes: false,
    filterVariant: 'multi-select',
    filterSelectOptions: selectOptions,
    enableResizing: true,
    minSize: 80,
    maxSize: 150,
    filterFn: (
      row: Row<FailuresListEntry>,
      columnId: string,
      filterValue: any
    ) => {
      if (!filterValue) {
        return true
      }

      const failureType = row.getValue<string>(columnId)

      const matchesTags = (filter: string): boolean => {
        const filterUpper = filter.toString().toUpperCase()
        return failureType.includes(filterUpper)
      }

      if (typeof filterValue === 'string') {
        if (filterValue === '') {
          return true
        }
        return matchesTags(filterValue)
      }

      if (Array.isArray(filterValue)) {
        const filterValues = filterValue as string[]

        if (filterValues.length === 0) {
          return true
        }

        return filterValues.some((filterValue) => {
          return matchesTags(filterValue)
        })
      }

      return false
    },
    Cell: (data) => {
      const value = data.cell
        .getValue<string>()
        .toLocaleUpperCase()
        .replaceAll('FAILURETYPE_', '')
      return (
        <Tooltip arrow title={value}>
          <Typography variant='body2' fontSize={mediumFontSize}>
            {value}
          </Typography>
        </Tooltip>
      )
    }
  }
}
