import React, { FC } from 'react'
import './alarmCardActions.sass'
import {
  Failure,
  UpdatedFailureAcknowledgementData
} from '../../../../../Contexts/types'
import { AcknowledgmentSwitch } from './AcknowledgmentSwitch/acknowledgmentSwitch'
import { MutingSwitch } from '../../../../../MutingSwitch/mutingSwitch'
import { useCaseContext } from '../../../../../Contexts'
import { MutingButtonStyle } from '../../../../../MutingSwitch/mutingButtonStyle'

interface AlarmCardActionsProps {
  failure: Failure
  caseId: string
  updateFailureAcknowledgement: (
    acknowledgement: UpdatedFailureAcknowledgementData
  ) => void
}

export const AlarmCardActions: FC<AlarmCardActionsProps> = ({
  failure,
  caseId,
  updateFailureAcknowledgement
}) => {
  const { muteFailure, unmuteFailure } = useCaseContext()

  return (
    <div className='alarm-card-actions'>
      <AcknowledgmentSwitch
        caseId={caseId}
        failure={failure}
        updateFailureAcknowledgement={updateFailureAcknowledgement}
      />
      <MutingSwitch
        caseId={caseId}
        mutedUntil={
          failure.mutedUntil === undefined
            ? undefined
            : new Date(failure.mutedUntil)
        }
        failureType={failure.failureType}
        onMute={muteFailure}
        onUnmute={unmuteFailure}
        mutingButtonStyle={MutingButtonStyle.detailView}
      />
    </div>
  )
}
