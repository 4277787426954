import { MRT_ColumnDef } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import React, { useMemo } from 'react'
import { Row } from '@tanstack/table-core/src/types'
import { Box, Typography } from '@mui/material'
import { mediumFontSize, smallFontSize } from '../cellFontSizes'

const unassigned = 'Unassigned'

export const assigneeColumnId = 'assignee'

export const useAssigneeColumnHeader = (
  data: FailuresListEntry[]
): MRT_ColumnDef<FailuresListEntry> => {
  const selectOptions = useMemo<string[]>(() => {
    const allAssignees = data.map((entry) =>
      entry.assignee ? entry.assignee : unassigned
    )

    const distinctAssignees = allAssignees.filter(
      (item, index) => allAssignees.indexOf(item) === index
    )

    return distinctAssignees.sort((a, b) => (a > b ? 1 : -1))
  }, [data])

  return {
    id: assigneeColumnId,
    header: 'Assignee',
    accessorKey: 'assignee',
    enableColumnDragging: false,
    enableColumnActions: false,
    enableGrouping: true,
    enableClickToCopy: false,
    enableColumnFilterModes: false,
    enableResizing: false,
    filterVariant: 'multi-select',
    filterSelectOptions: selectOptions,
    minSize: 120,
    maxSize: 120,
    filterFn: (
      row: Row<FailuresListEntry>,
      columnId: string,
      filterValue: any
    ) => {
      if (!filterValue) {
        return false
      }

      let assignee = row.getValue<string>(columnId)

      if (!assignee) {
        assignee = unassigned
      }

      const matchesTags = (filter: string): boolean => {
        const filterUpper = filter.toString().toUpperCase()
        return assignee.toUpperCase().includes(filterUpper)
      }

      if (typeof filterValue === 'string') {
        if (filterValue === '') {
          return true
        }
        return matchesTags(filterValue)
      }

      if (Array.isArray(filterValue)) {
        const filterValues = filterValue as string[]

        if (filterValues.length === 0) {
          return true
        }

        return filterValues.some((filterValue) => {
          return matchesTags(filterValue)
        })
      }

      return false
    },
    accessorFn: (row: FailuresListEntry) => {
      const assignee = row.assignee
      return assignee ? `${assignee}` : unassigned
    },
    Cell: (data) => {
      const value = data.cell.getValue<string>()
      if (!value) {
        return <div>{unassigned}</div>
      }

      const mailSegments = value.split('@')

      if (mailSegments.length === 1) {
        const name = mailSegments[0]
        return <div>{name}</div>
      }

      const name = mailSegments[0]
      const domain = mailSegments[1]
      return (
        <Box>
          <Typography variant='body2' fontSize={mediumFontSize}>
            {name}
          </Typography>
          <Typography variant='body2' fontSize={smallFontSize}>
            {domain}
          </Typography>
        </Box>
      )
    }
  }
}
