import React, { useMemo } from 'react'
import {
  formatDateString,
  formatWeekdayString
} from '../utils/formatDateString'
import { Box, Typography } from '@mui/material'
import { isFailureMutedForever } from './isFailureMuted'

interface IMutingSwitchListViewLabelProps {
  mutedUntil?: Date
  isMuted: boolean
}

export const MutingSwitchListViewLabel: React.FC<
  IMutingSwitchListViewLabelProps
> = (props: IMutingSwitchListViewLabelProps) => {
  const isMutedForever = useMemo<boolean>(
    () => isFailureMutedForever(props.mutedUntil),
    [props.mutedUntil]
  )

  if (!props.isMuted) {
    return <></>
  }

  if (isMutedForever) {
    return (
      <Typography variant='body2' fontSize={12}>
        Forever
      </Typography>
    )
  }

  return (
    <Box
      style={{
        textAlign: 'left',
        margin: '0px'
      }}
    >
      <Typography variant='body2' fontSize={12}>
        {formatWeekdayString(props.mutedUntil)}
      </Typography>
      <Typography variant='body2' fontSize={12}>
        {formatDateString(props.mutedUntil)}
      </Typography>
    </Box>
  )
}
