import { Edit, RemoveCircleOutline } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ConfirmationDialog } from '../../../ConfirmationDialog'
import { useCaseContext } from '../../../Contexts'
import { Comment } from '../../../Contexts/types'
import { useAuth } from '../../../Contexts/AuthContext'
import { CommentForm } from './commentForm'
import { SnackbarNotification } from '../../../Snackbar/snackbarNotification'
import { formatDateTimeString } from '../../../utils/formatDateString'
import './singleComment.sass'

interface SingleCommentProps {
  comment: Comment
}

export const SingleComment: React.FC<SingleCommentProps> = (props) => {
  const { created, text, author, changed, id } = props.comment
  const { removeComment } = useCaseContext()

  const [isEditing, setIsEditing] = useState(false)

  const [newComment, setNewComment] = useState('')
  const [loading, setLoading] = useState(false)
  const { caseId } = useParams()
  const { userEmail } = useAuth()
  const [error, setError] = useState<string | null>(null)

  const { updateComment } = useCaseContext()

  const isDisabled = !newComment || loading || !userEmail || !caseId

  const handleSubmitComment = async () => {
    if (isDisabled) {
      return
    }
    if (text === newComment) {
      setIsEditing(false)
      return
    }
    setLoading(true)
    try {
      await updateComment({
        author: userEmail,
        commentId: id,
        caseId: caseId,
        text: newComment
      })
      setIsEditing(false)
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      } else {
        setError('Failed to update the comment')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleCancelAddComment = () => {
    setIsEditing(false)
  }

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const displayedAuthor = author || 'Unknown'

  const createdAtValue = formatDateTimeString(new Date(created))
  const updatedAtValue = changed && formatDateTimeString(new Date(changed))

  const handleConfirm = async () => {
    if (!caseId) {
      return
    }
    try {
      await removeComment({ caseId, commentId: id })
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      } else {
        setError('Failed to update the comment')
      }
    } finally {
      setIsDialogOpen(false)
    }
  }

  return (
    <>
      {error && (
        <SnackbarNotification
          message={error}
          onClose={() => setError(null)}
          severity='error'
        />
      )}
      <ConfirmationDialog
        title='Are you sure you want to delete this comment?'
        onCancel={() => setIsDialogOpen(false)}
        isOpen={isDialogOpen}
        onConfirm={handleConfirm}
      />
      <Box
        component='li'
        display='grid'
        gridTemplateColumns='auto 1fr'
        alignItems='center'
        justifyContent='start'
      >
        <div>
          <span className='comment-author'>{displayedAuthor}</span>
          <span className='comment-date'>
            {createdAtValue}
            {updatedAtValue && <span>(updated at {updatedAtValue})</span>}
          </span>
        </div>
        <Box display='flex' alignContent='center'>
          <IconButton
            sx={{ width: 20, height: 20 }}
            onClick={() => setIsDialogOpen(true)}
          >
            <RemoveCircleOutline sx={{ color: '#0080f0', fontSize: 14 }} />
          </IconButton>
          <IconButton
            sx={{ width: 20, height: 20 }}
            onClick={() => {
              setNewComment(text)
              setIsEditing((editing) => !editing)
            }}
          >
            <Edit sx={{ color: '#0080f0', fontSize: 14 }} />
          </IconButton>
        </Box>
        {!isEditing ? (
          <div className={'comment-value'}>{text}</div>
        ) : (
          <div>
            <CommentForm
              value={newComment}
              disabledConfirm={text === newComment}
              onCancel={handleCancelAddComment}
              onSubmit={handleSubmitComment}
              onChange={(value) => setNewComment(value)}
              loading={loading}
            />
          </div>
        )}
      </Box>
    </>
  )
}
