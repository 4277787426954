import { MRT_ColumnDef } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import React, { useMemo } from 'react'
import { CheckCircleOutline, RadioButtonUnchecked } from '@mui/icons-material'
import { Box } from '@mui/material'

export const acknowledgedColumnId = 'isAcknowledged'

export const useAcknowledgedColumnHeader =
  (): MRT_ColumnDef<FailuresListEntry> => {
    const selectOptions = useMemo<string[]>(() => {
      return ['YES', 'NO']
    }, [])

    return {
      id: acknowledgedColumnId,
      header: 'Acknowledged',
      accessorKey: 'isAcknowledged',
      visibleInShowHideMenu: true,
      enableColumnFilter: true,
      enableGrouping: false,
      enableSorting: false,
      enableHiding: false,
      enablePinning: false,
      enableResizing: false,
      enableMultiSort: false,
      enableColumnOrdering: true,
      enableEditing: false,
      enableColumnDragging: false,
      enableColumnActions: false,
      enableGlobalFilter: true,
      enableColumnFilterModes: false,
      filterVariant: 'select',
      filterSelectOptions: selectOptions,
      minSize: 88,
      maxSize: 88,
      filterFn: (row, id, filterValue) => {
        const isAcknowledged = row.original.isAcknowledged
        return (
          (filterValue === 'YES' && isAcknowledged) ||
          (filterValue === 'NO' && !isAcknowledged)
        )
      },
      Cell: (data) => {
        const entry = data.row.original
        const isAcknowledged = entry.isAcknowledged
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}
          >
            {isAcknowledged ? (
              <CheckCircleOutline sx={{ color: 'green' }} />
            ) : (
              <RadioButtonUnchecked />
            )}
          </Box>
        )
      }
    }
  }
