export const isFailureMuted = (mutedUntil: Date | undefined): boolean => {
    if (mutedUntil === undefined) {
        return false
    }

    const mutedUntilTimestamp = mutedUntil.getTime()

    return mutedUntilTimestamp > Date.now()
}

export const isFailureMutedForever = (mutedUntil: Date | undefined): boolean => {
    if (mutedUntil === undefined) {
        return false
    }

    const year = mutedUntil.getUTCFullYear()

    return year >= 3000
}