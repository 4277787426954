import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import React from 'react'
import { FailuresListEntry } from '../../models/failuresListEntry'
import { Box, Typography } from '@mui/material'
import {
  formatDateString,
  formatTimeString
} from '../../../utils/formatDateString'
import { mediumFontSize } from '../cellFontSizes'

export const lastOccurrenceColumnId = 'lastOccurrence'

export const useLastOccurrenceColumnHeader =
  (): MRT_ColumnDef<FailuresListEntry> => {
    return {
      id: lastOccurrenceColumnId,
      header: 'Last Occurrence',
      accessorKey: 'lastOccurrence',
      enableColumnDragging: false,
      enableColumnActions: false,
      enableColumnFilterModes: false,
      enableResizing: false,
      filterVariant: 'date-range',
      enableClickToCopy: false,
      minSize: 100,
      maxSize: 100,
      accessorFn: (row) => row.lastOccurrence,
      Cell: ({ row }: { row: MRT_Row<FailuresListEntry> }) => {
        const lastOccurrence = row.original.lastOccurrence
        return (
          <Box>
            <Typography variant='body2' fontSize={mediumFontSize}>
              {formatDateString(lastOccurrence)}
            </Typography>
            <Typography variant='body2' fontSize={mediumFontSize}>
              {formatTimeString(lastOccurrence)}
            </Typography>
          </Box>
        )
      }
    }
  }
