import React, { useMemo } from 'react'
import { Box, CircularProgress, Switch } from '@mui/material'
import { MutingSwitchListViewLabel } from './mutingSwitchListViewLabel'
import { isFailureMuted } from './isFailureMuted'

interface MutingSwitchListViewProps {
  isLoading: boolean
  mutedUntil?: Date
  onMutedChanged: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => Promise<void>
}

export const MutingSwitchListView: React.FC<MutingSwitchListViewProps> = ({
  isLoading,
  mutedUntil,
  onMutedChanged
}) => {
  const isMuted = useMemo<boolean>(
    () => isFailureMuted(mutedUntil),
    [mutedUntil]
  )

  if (isLoading) {
    return (
      <Box sx={{ height: '38px' }}>
        <CircularProgress style={{ height: 30, width: 30, margin: 6 }} />
      </Box>
    )
  }

  return (
    <Box sx={{ justifyContent: 'left' }}>
      <Switch size='small' checked={isMuted} onChange={onMutedChanged} />
      <MutingSwitchListViewLabel mutedUntil={mutedUntil} isMuted={isMuted} />
    </Box>
  )
}
