import React, { CSSProperties, FC } from 'react'
import './alarmCardDetail.sass'
import { Box, Grid } from '@mui/material'

interface AlarmCardDetailProps {
  label: string
  value: string
  valueStyles?: CSSProperties
}

export const AlarmCardDetail: FC<AlarmCardDetailProps> = ({
  label,
  value,
  valueStyles
}) => {
  return (
    <Box className='alarm-card-detail'>
      <Grid container>
        <Grid item xs={5}>
          <Box className='alarm-card-detail-key' data-testid={`${label}-label`}>
            {label}
          </Box>
        </Grid>
        <Grid
          item
          xs={7}
          container
          alignItems='center'
          justifyContent='flex-end'
        >
          <Box className={'alarm-card-detail-value'} sx={valueStyles}>
            {value}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
