import React, { useEffect, useState } from 'react'
import { Chip } from '@mui/material'

type LastSynchronizationLabelProps = {
  lastSyncAt: string | number
  syncInProgress?: boolean
}

const LastSynchronizationLabel: React.FC<LastSynchronizationLabelProps> = (
  props
) => {
  const [timeDiff, setTimeDiff] = useState<string>('now')

  const calculateTimeDiff = () => {
    if (!props.lastSyncAt) {
      return
    }

    const lastSyncAtDate = new Date(props.lastSyncAt)
    const now = new Date()
    const diffInSeconds = Math.floor(
      (now.getTime() - lastSyncAtDate.getTime()) / 1000
    )

    if (diffInSeconds < 60) {
      setTimeDiff('now')
    } else if (diffInSeconds < 3600) {
      const diffInMinutes = Math.floor(diffInSeconds / 60)
      setTimeDiff(`${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`)
    } else if (diffInSeconds < 86400) {
      const diffInHours = Math.floor(diffInSeconds / 3600)
      setTimeDiff(`${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`)
    } else {
      const diffInDays = Math.floor(diffInSeconds / 86400)
      setTimeDiff(`${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`)
    }
  }

  useEffect(() => {
    calculateTimeDiff()

    const intervalId = setInterval(calculateTimeDiff, 60000)

    return () => clearInterval(intervalId)
  })

  return (
    <Chip
      label={
        props.syncInProgress
          ? 'Synchronizing....'
          : `Last synchronization: ${timeDiff}`
      }
      size='small'
      data-testid='last-synchronized-label'
      sx={{
        bgcolor: '#46c2d9',
        fontWeight: 'bold',
        color: 'white',
        height: '20px',
        borderRadius: '5px'
      }}
    />
  )
}

export default LastSynchronizationLabel
