export const formatLongDateTimeString = (date?: Date): string => {
  if (date === undefined) return ''

  return formatWeekdayString(date) + ', ' + formatDateTimeString(date)
}

export const formatLongDateString = (date?: Date): string => {
  if (date === undefined) return ''

  return formatWeekdayString(date) + ', ' + formatDateString(date)
}

export const formatDateTimeString = (date?: Date): string => {
  if (date === undefined) return ''

  return formatDateString(date) + ' ' + formatTimeString(date)
}
export const formatDateString = (date?: Date): string => {
  if (date === undefined) return ''

  return date.toLocaleString('de-DE', {
    timeZone: 'UTC',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
}
export const formatTimeString = (date?: Date): string => {
  if (date === undefined) return ''

  return date.toLocaleTimeString('de-DE', {
    timeZone: 'UTC',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })
}
export const formatWeekdayString = (date?: Date): string => {
  if (date === undefined) return ''

  return date.toLocaleString('en-GB', {
    timeZone: 'UTC',
    weekday: 'long'
  })
}
