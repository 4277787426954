import { localStorageKeys } from './localStorageKeys'
import { ISetFailuresStateActions } from './ISetFailuresStateActions'
import { getInitialState } from './initialState/getInitialState'

export const resetFilterAndSortingFromLocalStorage = (
  setActions: ISetFailuresStateActions
) => {
  localStorageKeys.forEach((key) => localStorage.removeItem(key))

  const initialState = getInitialState()
  setActions.setColumnFilters(initialState.columnFilters)
  setActions.setGlobalFilter(initialState.globalFilter)
  setActions.setSorting(initialState.sorting)
}
