import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import Snackbar, { SnackbarCloseReason } from '@mui/joy/Snackbar'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Typography } from '@mui/material'

type SnackbarSeverity = 'error' | 'warning' | 'info' | 'success'
type SnackbarColor = 'danger' | 'warning' | 'neutral' | 'success'

interface SnackbarNotificationProps {
  message?: string
  onClose?: () => void
  severity?: SnackbarSeverity
}

export const SnackbarNotification: React.FC<SnackbarNotificationProps> = ({
  message,
  onClose,
  severity = 'info'
}) => {
  const shouldOpenSnackbar = (message: string | undefined): boolean => {
    return message !== undefined && message !== ''
  }
  const getSnackbarColor = (severity: SnackbarSeverity): SnackbarColor => {
    if (severity === 'error') return 'danger'
    if (severity === 'warning') return 'warning'
    if (severity === 'success') return 'success'

    return 'neutral'
  }

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(
    shouldOpenSnackbar(message)
  )

  const snackbarColor = useMemo<SnackbarColor>(
    () => getSnackbarColor(severity),
    [severity]
  )

  useEffect(() => {
    setSnackbarOpen(shouldOpenSnackbar(message))
  }, [message])

  const handleSnackbarClose = useCallback(
    (_: unknown, reason: SnackbarCloseReason) => {
      if (reason === 'clickaway') {
        return
      }
      onClose?.()
      setSnackbarOpen(false)
    },
    [onClose]
  )

  const handleCloseIconClick = useCallback(() => {
    setSnackbarOpen(false)
  }, [])

  const getMultilineMessage = (message?: string): ReactNode => {
    if (message === undefined || message === '') {
      return <></>
    }

    const messageSplit = message.split('\n')

    return (
      <Box key={'SnackbarMultilineContent'}>
        {messageSplit.map((line, index) => (
          <Typography key={`line-${index}`}>{line}</Typography>
        ))}
      </Box>
    )
  }

  return (
    <Snackbar
      data-testid={'snackbar-test-id'}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={snackbarOpen}
      autoHideDuration={3000}
      onClose={handleSnackbarClose}
      key={'top-right'}
      sx={{
        width: 'auto',
        padding: 0,
        marginTop: '0px'
      }}
      color={snackbarColor}
      variant='plain'
    >
      <Alert
        severity={severity ?? 'info'}
        variant='standard'
        sx={{ width: '100%' }}
        action={
          <IconButton
            data-testid={'snackbar-close-button'}
            aria-label='close'
            color='inherit'
            size='small'
            onClick={handleCloseIconClick}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        }
      >
        {getMultilineMessage(message)}
      </Alert>
    </Snackbar>
  )
}
