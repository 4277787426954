import React, { useMemo } from 'react'
import {
  Box,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch
} from '@mui/material'
import { MutingSwitchDetailViewLabel } from './mutingSwitchDetailViewLabel'
import { isFailureMuted } from './isFailureMuted'

interface MutingSwitchDetailViewProps {
  isLoading: boolean
  mutedUntil?: Date
  onMutedChanged: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => Promise<void>
}

export const MutingSwitchDetailView: React.FC<MutingSwitchDetailViewProps> = ({
  isLoading,
  mutedUntil,
  onMutedChanged
}) => {
  const isMuted = useMemo<boolean>(
    () => isFailureMuted(mutedUntil),
    [mutedUntil]
  )

  return (
    <Box sx={{ justifyContent: 'left', display: 'flex', marginLeft: '3px' }}>
      <FormGroup>
        <FormControlLabel
          control={
            isLoading ? (
              <CircularProgress style={{ height: 30, width: 30, margin: 6 }} />
            ) : (
              <Switch
                data-testid={'muting-switch'}
                size='medium'
                checked={isMuted}
                onChange={onMutedChanged}
              />
            )
          }
          label={
            <MutingSwitchDetailViewLabel
              mutedUntil={mutedUntil}
              isMuted={isMuted}
            />
          }
          labelPlacement='end'
        />
      </FormGroup>
    </Box>
  )
}
