import React, { useEffect, useMemo, useState } from 'react'
import { useCaseContext } from '../Contexts'
import './caseDetails.sass'
import { SnackbarNotification } from '../Snackbar/snackbarNotification'
import { PumpInfoPanelContainer } from './PumpInfoPanel/pumpInfoPanelContainer'
import { CaseDetailsContent } from './CaseDetailsContent/caseDetailsContent'
import { Box } from '@mui/material'

export const iconsProps = {
  width: 15,
  height: 15
}

export const CaseDetails = () => {
  const { isLoading, isError, currentCase, caseSyncedAt } = useCaseContext()
  const activeCase = useMemo(() => currentCase, [currentCase])
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  useEffect(() => {
    const title = currentCase ? currentCase.title : 'Loading'
    document.title = `IM | ${title}`
  }, [currentCase])

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  if (!activeCase?.id) {
    return <>No Case {JSON.stringify(activeCase)}</>
  }

  return (
    <>
      {snackbarMessage && (
        <SnackbarNotification
          message={snackbarMessage}
          onClose={() => setSnackbarMessage('')}
          severity='error'
        />
      )}
      <div className={'case-details'}>
        <Box display='flex'>
          <Box sx={{ width: '440px' }}>
            <PumpInfoPanelContainer activeCase={activeCase} />
          </Box>

          <Box sx={{ width: '1000px' }}>
            <CaseDetailsContent
              activeCase={activeCase}
              caseSyncedAt={caseSyncedAt}
              setSnackbarMessage={setSnackbarMessage}
            />
          </Box>
        </Box>
      </div>
    </>
  )
}
