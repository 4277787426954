import { MRT_ColumnDef } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import React, { useMemo } from 'react'
import { Typography } from '@mui/material'
import { mediumFontSize } from '../cellFontSizes'

export const countryCodeColumnId = 'countryCode'

export const useCountryCodeColumnHeader = (
  data: FailuresListEntry[]
): MRT_ColumnDef<FailuresListEntry> => {
  const selectOptions = useMemo<string[]>(() => {
    const countryCodes = data.map((entry) => entry.countryCode)
    const distinctCountryCodes = countryCodes.filter(
      (item, index) => countryCodes.indexOf(item) === index
    )
    return distinctCountryCodes.sort((a, b) => (a > b ? 1 : -1))
  }, [data])

  return {
    id: countryCodeColumnId,
    header: 'Country Code',
    accessorKey: 'countryCode',
    enableColumnDragging: false,
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableResizing: true,
    enableSorting: true,
    filterVariant: 'multi-select',
    filterSelectOptions: selectOptions,
    minSize: 50,
    maxSize: 100,
    Cell: ({ row }) => {
      const countryCode = row.original.countryCode
      return (
        <Typography variant='body2' fontSize={mediumFontSize}>
          {countryCode}
        </Typography>
      )
    }
  }
}
