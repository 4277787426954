export type Url = {
  url: string
  label: string
  id: string
}

export type Comment = {
  id: string
  created: string
  author: string
  text: string
  changed?: string
}

export type Attribute = {
  key: string
  value: string
}

export type Failure = {
  failureType: string
  failureState: string
  value: number
  firstOccurrence: string
  lastOccurrence: string
  acknowledged: boolean
  violatedThresholdValue?: number
  autoFeedback?: boolean
  mutedUntil?: string
  highestFailureState?: string
}

export type History = {
  text: string
  created: string
  creator: string
}

export type Case = {
  id: string
  title: string
  description: string
  status: string
  created: string
  muted: boolean
  tags: string[]
  guardTags: string[]
  urls: Url[]
  comments: Comment[]
  attributes: Attribute[]
  history: History[]
  assignee: string
  firstAlarmOccurrence: string
  lastAlarmOccurrence: string
  failures: Failure[]
}

export interface CaseListEntry {
  id: string
  pumpId: string
  pumpName: string
  pumpTypeSeries: string
  pumpSerialNumber: string
  pumpLocation: string
  pumpTechnicalLocation: string
  status: string
  organizationId: string
  organizationName: string
  pumpManufacturer: string
  pumpSensorsSerialNumber_0: string
  muted: boolean
  tags: string[]
  guardTags: string[]
  failures: Failure[]
  assignee: string
  created: string
  firstAlarmOccurrence: string
  lastAlarmOccurrence: string
  pumpCountryCode: string
}

export type User = {
  email: string
}

export enum CaseStatusEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}

export interface UpdatedCaseData {
  id: string
  title: string
  description: string
  status: CaseStatusEnum
  assignee?: string
}

export interface UpdatedFailureAcknowledgementData {
  acknowledged: boolean
  caseId: string
  failureType: string
}

export interface IPostTags {
  id: string
  tag: string
}

export enum FailureTypes {
  VIBRATION = 'VIBRATION',
  TEMPERATUREHIGH = 'TEMPERATUREHIGH',
  TEMPERATURELOW = 'TEMPERATURELOW',
  BATTERYSTATE = 'BATTERYSTATE',
  OPERATINGHOURS_1STBEARINGLUBRICATION = 'OPERATINGHOURS_1STBEARINGLUBRICATION',
  OPERATINGHOURS_BEARINGLUBRICATION = 'OPERATINGHOURS_BEARINGLUBRICATION',
  OPERATINGHOURS_BEARING = 'OPERATINGHOURS_BEARING'
}
