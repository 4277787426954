import { Grid } from '@mui/material'
import React from 'react'
import { History } from '../../../Contexts/types'
import './singleHistoryEntry.sass'
import { formatDateTimeString } from '../../../utils/formatDateString'
import { DebugBorder } from '../../../utils/debugBorder'

interface SingleHistoryEntryProps {
  historyEntry: History
}

export const SingleHistoryEntry: React.FC<SingleHistoryEntryProps> = (
  props
) => {
  const { creator, created, text } = props.historyEntry
  const multilineText = text
    .trim()
    .split('\n')
    .map((t, index) => {
      return (
        <p key={index} className='history-entry-value'>
          {t}
        </p>
      )
    })
  return (
    <DebugBorder>
      <div className='history-entry'>
        <Grid container>
          <Grid item xs={12}>
            <span className='history-entry-author'>{creator}</span>
            <span className='history-entry-date'>
              {formatDateTimeString(new Date(created))}
            </span>
          </Grid>
          <Grid item xs={12}>
            {multilineText}
          </Grid>
        </Grid>
      </div>
    </DebugBorder>
  )
}
