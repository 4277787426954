import React, { useMemo } from 'react'
import { formatLongDateString } from '../utils/formatDateString'
import { Typography } from '@mui/material'
import { isFailureMutedForever } from './isFailureMuted'

interface IMutingSwitchDetailViewLabelProps {
  mutedUntil?: Date
  isMuted: boolean
}

export const MutingSwitchDetailViewLabel: React.FC<
  IMutingSwitchDetailViewLabelProps
> = (props: IMutingSwitchDetailViewLabelProps) => {
  const isMutedForever = useMemo<boolean>(
    () => isFailureMutedForever(props.mutedUntil),
    [props.mutedUntil]
  )

  if (!props.isMuted || props.mutedUntil === undefined) {
    return <Typography>Not Muted</Typography>
  }

  if (isMutedForever) {
    return <Typography>Muted forever</Typography>
  }

  return (
    <Typography>
      Muted until {formatLongDateString(props.mutedUntil)}
    </Typography>
  )
}
