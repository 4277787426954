import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { mediumFontSize, smallFontSize } from '../cellFontSizes'

export const pumpColumnId = 'pumpColumnId'

export const usePumpColumnHeader = (): MRT_ColumnDef<FailuresListEntry> => {
  return {
    id: pumpColumnId,
    header: 'Pump',
    accessorFn(originalRow) {
      return (
        originalRow.pumpId +
        ' ' +
        originalRow.pumpName +
        ' ' +
        originalRow.pumpSerialNumber
      )
    },
    enableColumnDragging: false,
    enableColumnActions: false,
    enableGrouping: true,
    enableColumnFilterModes: false,
    enableResizing: true,
    minSize: 100,
    maxSize: 200,
    Cell: ({ row }: { row: MRT_Row<FailuresListEntry> }) => {
      const pumpName = row.original.pumpName
      const pumpSerialNumber = row.original.pumpSerialNumber
      return (
        <Tooltip
          title={
            <Box>
              <Typography variant='body2'>{pumpName}</Typography>
              <Typography fontSize={10}>SN: {pumpSerialNumber}</Typography>
            </Box>
          }
        >
          <Box>
            <Typography variant='body2' fontSize={mediumFontSize}>
              {pumpName}
            </Typography>
            <Typography fontSize={smallFontSize}>{pumpSerialNumber}</Typography>
          </Box>
        </Tooltip>
      )
    }
  }
}
