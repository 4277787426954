import React, { FC, useState } from 'react'
import { Grid } from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { MutingButtonStyle } from './mutingButtonStyle'
import { MutingDialog } from './mutingDialog'
import { SnackbarNotification } from '../Snackbar/snackbarNotification'
import { MutingSwitchDetailView } from './mutingSwitchDetailView'
import { MutingSwitchListView } from './mutingSwitchListView'
import Alert from '@mui/material/Alert'

dayjs.extend(utc)

export interface MutingSwitchProps {
  caseId: string
  failureType: string
  mutedUntil?: Date
  onMute: (failureType: string, mutedUntil?: Date) => Promise<void>
  onUnmute: (failureType: string) => Promise<void>
  mutingButtonStyle: MutingButtonStyle
}

export const MutingSwitch: FC<MutingSwitchProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [mutedUntil, setMutedUntil] = useState<Date | undefined>(
    props.mutedUntil
  )

  const muteStatusSwitchOnChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): Promise<void> => {
    if (checked) {
      setIsDialogOpen(true)
    } else {
      try {
        setIsLoading(true)
        await props.onUnmute(props.failureType)
        setMutedUntil(undefined)
      } catch (error) {
        setErrorMessage(
          `Could not unmute failure type ${props.failureType.toUpperCase()}`
        )
      } finally {
        setIsLoading(false)
      }
    }
  }

  const muteOnConfirm = async (chosenMutedUntil?: Date): Promise<void> => {
    try {
      if (chosenMutedUntil === undefined) {
        return
      }
      setIsLoading(true)
      await props.onMute(props.failureType, chosenMutedUntil)
      setMutedUntil(chosenMutedUntil)
    } catch (error) {
      setErrorMessage(
        `Could not mute failure type ${props.failureType.toUpperCase()}`
      )
    } finally {
      setIsLoading(false)
      setIsDialogOpen(false)
    }
  }

  const checkPropsAreValid = () => {
    return (
      props.caseId === undefined ||
      props.caseId === '' ||
      props.failureType === '' ||
      props.onUnmute === undefined ||
      props.onMute === undefined ||
      props.mutingButtonStyle === undefined
    )
  }

  if (checkPropsAreValid()) {
    return (
      <Alert severity={'error'} variant='standard'>
        Invalid parameter for muting switch component
      </Alert>
    )
  }

  return (
    <>
      <SnackbarNotification
        key={'MutingSnackbar'}
        message={errorMessage}
        onClose={() => setErrorMessage(undefined)}
        severity='error'
      />
      <MutingDialog
        failureType={props.failureType}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        muteOnConfirm={muteOnConfirm}
      />
      <Grid container data-testid={`${props.caseId}-${props.failureType}-muting-toggle`}>
        {props.mutingButtonStyle === MutingButtonStyle.listView ? (
          <MutingSwitchListView
            isLoading={isLoading}
            mutedUntil={mutedUntil}
            onMutedChanged={muteStatusSwitchOnChange}
          />
        ) : (
          <MutingSwitchDetailView
            isLoading={isLoading}
            mutedUntil={mutedUntil}
            onMutedChanged={muteStatusSwitchOnChange}
          />
        )}
      </Grid>
    </>
  )
}
