import React, { useEffect, useMemo } from 'react'
import './failuresList.sass'
import {
  MaterialReactTable,
  MRT_TableInstance,
  useMaterialReactTable
} from 'material-react-table'
import { FailuresListEntry } from './models/failuresListEntry'
import { useFailureListState } from './state/useFailureListState'
import { useFailureListColumns } from './columnHeaders/useFailureListColumns'
import { ToolbarCustomActions } from './components/toolbarCustomActions'

interface IFailuresListProps {
  data: FailuresListEntry[]
  refreshAction: () => Promise<void>
  onEntryChanged: (old: FailuresListEntry, updated: FailuresListEntry) => void
  isLoading: boolean
  isRefetching: boolean
  dataUpdatedAt: number
}

const tagsToIgnore = ['TEST / DEMO', 'SENSOR DEFECT']

export const FailuresList: React.FC<IFailuresListProps> = (props) => {
  useEffect(() => {
    document.title = `IM | Issues`
  }, [])

  const {
    resetFilterAndSorting,
    columnOrder,
    setColumnOrder,
    columnFilters,
    setColumnFilters,
    columnVisibility,
    setColumnVisibility,
    density,
    setDensity,
    globalFilter,
    setGlobalFilter,
    showGlobalFilter,
    setShowGlobalFilter,
    showColumnFilters,
    setShowColumnFilters,
    sorting,
    setSorting,
    grouping,
    setGrouping,
    pagination,
    setPagination,
    expanded,
    setExpanded,
    columnPinning,
    setColumnPinning,
    hideSpecificTags,
    setHideSpecificTags
  } = useFailureListState()

  const filteredData = useMemo<FailuresListEntry[]>(() => {
    if (!hideSpecificTags) {
      return props.data
    }

    return props.data.filter((entry) => {
      return !entry.tags.some((tag) =>
        tagsToIgnore.some((tagToIgnore) =>
          tag.value.toUpperCase().includes(tagToIgnore.toUpperCase())
        )
      )
    })
  }, [props.data, hideSpecificTags])

  const columns = useFailureListColumns(filteredData, props.onEntryChanged)

  const table: MRT_TableInstance<FailuresListEntry> = useMaterialReactTable({
    columns: columns,
    data: filteredData,
    enableRowSelection: false,
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    enableStickyHeader: false,
    enableStickyFooter: false,
    enableExpanding: false,
    enableRowPinning: false,
    enableGrouping: false,
    enableDensityToggle: false,
    enableColumnPinning: false,
    enableColumnFilterModes: false,
    enableSortingRemoval: true,
    enableHiding: true,
    enableColumnResizing: true,
    enableFilterMatchHighlighting: false,
    enableFullScreenToggle: false,
    columnFilterDisplayMode: 'popover',
    positionGlobalFilter: 'right',
    layoutMode: 'grid',
    isMultiSortEvent: () => true,
    onColumnOrderChange: setColumnOrder,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onDensityChange: setDensity,
    onGlobalFilterChange: setGlobalFilter,
    onShowColumnFiltersChange: setShowColumnFilters,
    onShowGlobalFilterChange: setShowGlobalFilter,
    onSortingChange: setSorting,
    onGroupingChange: setGrouping,
    onPaginationChange: setPagination,
    onExpandedChange: setExpanded,
    onColumnPinningChange: setColumnPinning,
    state: {
      columnOrder,
      columnFilters,
      columnVisibility,
      density,
      globalFilter,
      showGlobalFilter,
      showColumnFilters,
      sorting,
      grouping,
      pagination,
      expanded,
      columnPinning,
      showProgressBars: props.isLoading
    },
    muiFilterTextFieldProps: {
      sx: { m: '0', p: '0', width: '100%' },
      variant: 'outlined'
    },

    renderTopToolbarCustomActions: () => (
      <ToolbarCustomActions
        refreshAction={props.refreshAction}
        resetFilterAndSorting={resetFilterAndSorting}
        tagsToIgnore={tagsToIgnore}
        hideSpecificTags={hideSpecificTags}
        setHideSpecificTags={setHideSpecificTags}
        isRefetching={props.isRefetching}
        dataUpdatedAt={props.dataUpdatedAt}
      />
    )
  })

  return (
    <div data-testid='failures-list-table'>
      <MaterialReactTable table={table} />
    </div>
  )
}
