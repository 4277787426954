import React, { FC } from 'react'
import './acknowledgmentSwitch.sass'
import { Grid } from '@mui/material'
import {
  Failure,
  UpdatedFailureAcknowledgementData
} from '../../../../../../Contexts/types'
import { StatusSwitch } from '../../../../../../StatusSwitch/statusSwitch'
import { useAcknowledgementSwitchHook } from './useAcknowledgementSwitchHook'

export interface AcknowledgementSwitchProps {
  failure: Failure
  caseId: string
  updateFailureAcknowledgement: (
    acknowledgement: UpdatedFailureAcknowledgementData
  ) => void
}

export const AcknowledgmentSwitch: FC<AcknowledgementSwitchProps> = (props) => {
  const { isAcknowledged, handleChangeToggle } =
    useAcknowledgementSwitchHook(props)

  return (
    <Grid
      container
      data-testid={`${props.failure.failureType}-acknowledgement-toggle`}
    >
      <StatusSwitch
        label={isAcknowledged ? 'Acknowledged' : 'Not Acknowledged'}
        onChange={handleChangeToggle}
        isChecked={isAcknowledged}
        width={'100%'}
      />
    </Grid>
  )
}
