export const muteFailureRequest = async (
  token: string,
  casesUrl: string,
  caseId: string,
  failureType: string,
  muteUntil?: Date
): Promise<void> => {
  try {
    if (muteUntil === undefined) {
      return
    }

    const response = await fetch(
      `${casesUrl}/${caseId}/failures/${failureType}/mute`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        },
        body: JSON.stringify({
          muteUntil
        })
      }
    )

    if (!response.ok) {
      throw new Error('Not successful response from mute-failure endpoint')
    }
  } catch (error) {
    console.error('Error occurred during failure muting', error)
    throw error
  }
}

export const unmuteFailureRequest = async (
  token: string, //Authorization: await auth!.getBearer()
  casesUrl: string,
  caseId: string,
  failureType: string,
  onDone?: () => Promise<any>
): Promise<void> => {
  try {
    const response = await fetch(
      `${casesUrl}/${caseId}/failures/${failureType}/mute`,
      {
        method: 'DELETE',
        headers: {
          Authorization: token
        }
      }
    )

    if (!response.ok) {
      throw new Error('Not successful response from unmute-failure endpoint')
    }

    if (onDone === undefined) {
      return
    }

    await onDone()
  } catch (error) {
    console.error('Error occurred during failure unmuting', error)
    throw error
  }
}
