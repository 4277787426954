import { MRT_ColumnDef } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import { IconButton, Link } from '@mui/material'
import { Info } from '@mui/icons-material'
import React from 'react'

export const openDetailsColumnId = 'openDetailsColumnId'

export const useOpenDetailsColumnHeader =
  (): MRT_ColumnDef<FailuresListEntry> => {
    return {
      id: openDetailsColumnId,
      accessorFn: (originalRow) => originalRow,
      header: 'Open',
      visibleInShowHideMenu: true,
      enableHiding: false,
      enableColumnOrdering: true,
      enableSorting: false,
      enableResizing: false,
      enableColumnDragging: false,
      enableGlobalFilter: false,
      enablePinning: false,
      enableColumnFilter: false,
      minSize: 74,
      maxSize: 74,
      Cell: ({ row }) => {
        const caseId = row.original.pumpId
        const caseDetailUrl = `/cases/${caseId}`

        return (
          <Link href={caseDetailUrl}>
            <IconButton data-testid='case-details-button'>
              <Info />
            </IconButton>
          </Link>
        )
      }
    }
  }
