import React, { SetStateAction, useMemo, useState } from 'react'
import { formatFailureType } from '../utils/formatFailureType'
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { formatLongDateTimeString } from '../utils/formatDateString'
import { ConfirmationDialog } from '../ConfirmationDialog'
import dayjs, { Dayjs } from 'dayjs'
import { isFailureMutedForever } from './isFailureMuted'

interface IMutingDialogProps {
  failureType: string
  isDialogOpen: boolean
  setIsDialogOpen: React.Dispatch<SetStateAction<boolean>>
  muteOnConfirm: (mutedUntil?: Date) => Promise<void>
}

export const MutingDialog: React.FC<IMutingDialogProps> = (
  props: IMutingDialogProps
) => {
  const toggleButtonOnChange = (
    event: React.MouseEvent<HTMLElement> | null,
    newValue: string | null
  ) => {
    if (newValue !== null) {
      setActiveToggleButton(newValue)

      if (newValue === '1day') {
        setMuteUntil(buildMutedUntilWithDaysOffset(1))
        setShowDatepicker(false)
      }
      if (newValue === '3days') {
        setMuteUntil(buildMutedUntilWithDaysOffset(3))
        setShowDatepicker(false)
      }
      if (newValue === '1week') {
        setMuteUntil(buildMutedUntilWithDaysOffset(7))
        setShowDatepicker(false)
      }
      if (newValue === 'forever') {
        setMuteUntil(new Date('3000-01-01T00:00:00.000Z'))
        setShowDatepicker(false)
      }
      if (newValue === 'custom') {
        setShowDatepicker(true)
      }
    }
  }

  const datepickerOnChange = (newValue: Dayjs | null) => {
    setShowDatepicker(false)
    setMuteUntil(new Date(dayjs(newValue).toISOString()))
    toggleButtonOnChange(null, '')
  }

  const buildMutedUntilWithDaysOffset = (daysOffset: number): Date => {
    return new Date(
      dayjs().utc().add(daysOffset, 'day').startOf('day').toISOString()
    )
  }

  const datepickerDisablePastAndToday = (date: Dayjs): boolean => {
    if (dayjs(date).isSame(dayjs(), 'day')) {
      return true
    }

    return dayjs(date).isBefore(dayjs(), 'day')
  }

  const [mutedUntil, setMuteUntil] = useState<Date | undefined>(
    buildMutedUntilWithDaysOffset(1)
  )
  const [showDatepicker, setShowDatepicker] = useState(false)
  const [activeToggleButton, setActiveToggleButton] = useState('1day')

  const isMutedForever = useMemo<boolean>(
    () => isFailureMutedForever(mutedUntil),
    [mutedUntil]
  )

  return (
    <ConfirmationDialog
      title={`Muting ${formatFailureType(props.failureType)}`}
      onCancel={() => props.setIsDialogOpen(false)}
      isOpen={props.isDialogOpen}
      onConfirm={() => props.muteOnConfirm(mutedUntil)}
      confirmLabel={'Mute'}
    >
      <ToggleButtonGroup
        data-testid={'muting-selection'}
        value={activeToggleButton}
        exclusive
        onChange={toggleButtonOnChange}
        sx={{ justifyContent: 'left', padding: '0 20px 10px' }}
      >
        <ToggleButton data-testid={'toggle-button-1-day'} value='1day'>1 day</ToggleButton>
        <ToggleButton data-testid={'toggle-button-3-days'} value='3days'>3 days</ToggleButton>
        <ToggleButton data-testid={'toggle-button-1-week'} value='1week'>1 week</ToggleButton>
        <ToggleButton data-testid={'toggle-button-forever'} value='forever'>Forever</ToggleButton>
        <ToggleButton data-testid={'toggle-button-choose-date'} value='custom'>choose date</ToggleButton>
      </ToggleButtonGroup>
      {showDatepicker ? (
        <StaticDatePicker
          timezone={'UTC'}
          shouldDisableDate={datepickerDisablePastAndToday}
          onChange={datepickerOnChange}
          views={['day']}
          slotProps={{
            toolbar: {
              hidden: true
            },
            actionBar: {
              actions: []
            }
          }}
        />
      ) : (
        <Grid container sx={{ padding: '20px', minWidth: '600px' }}>
          <Grid item xs={6} sx={{ color: 'gray' }} data-testid={'muted-until-text'}>
            {formatFailureType(props.failureType)} will be muted until{' '}
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
            data-testid={'muted-until-preview'}
          >
            {isMutedForever ? 'forever' : formatLongDateTimeString(mutedUntil)}
          </Grid>
        </Grid>
      )}
    </ConfirmationDialog>
  )
}
