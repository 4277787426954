import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import React from 'react'
import { FailuresListEntry } from '../../models/failuresListEntry'
import { Box, Typography } from '@mui/material'
import {
  formatDateString,
  formatTimeString
} from '../../../utils/formatDateString'
import { mediumFontSize } from '../cellFontSizes'

export const firstOccurrenceColumnId = 'firstOccurrence'

export const useFirstOccurrenceColumnHeader =
  (): MRT_ColumnDef<FailuresListEntry> => {
    return {
      id: firstOccurrenceColumnId,
      header: 'First Occurrence',
      accessorKey: 'firstOccurrence',
      enableColumnDragging: false,
      enableColumnActions: false,
      enableColumnFilterModes: false,
      enableResizing: false,
      filterVariant: 'date-range',
      enableClickToCopy: false,
      minSize: 100,
      maxSize: 100,
      accessorFn: (row) => row.firstOccurrence,
      Cell: ({ row }: { row: MRT_Row<FailuresListEntry> }) => {
        const firstOccurrence = row.original.firstOccurrence

        if (!firstOccurrence || isNaN(firstOccurrence.getTime())) {
          return <Box></Box>
        }

        return (
          <Box>
            <Typography variant='body2' fontSize={mediumFontSize}>
              {formatDateString(firstOccurrence)}
            </Typography>
            <Typography variant='body2' fontSize={mediumFontSize}>
              {formatTimeString(firstOccurrence)}
            </Typography>
          </Box>
        )
      }
    }
  }
